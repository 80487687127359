@import '../../../styles/settings';
@import '../../../styles/ci/mixins';
@import 'home-banner';
/*@import '../../Magento_Catalog/styles/printer-selector';*/

.cms-home {
    .page-main {
        margin-top: 0;
    }
}

.heading {
    margin-top: 0;
}

.home-section {
    margin: 4rem auto;
}

.home-top-brands {
    .wrapper {
        background: #efefef;
        margin: 1rem 0;
        padding: 1rem 1rem 2rem;
    }
    .content {
        text-align: center;
    }
    .brand-icons {
        display: inline-block;
        margin: 1rem 0;
        filter: brightness(0.7);
    }
}

.home-help {
    background-color: $dark-gray;
    color: $white;
    padding: 1rem 0;
    .title {
        margin-top: 0;
        margin-bottom: 0.15rem;
    }
    .wrapper {
        margin: 0.5rem 0;
        padding: 0 2rem;
    }
    .content {
        padding-left: 1rem;
    }
    .help-icon {
        max-width: 100%;
    }
    .help-title {
        margin-left: 2rem;
        margin-bottom: 2rem;
        font-family: $sec-font;
    }
}

.home-reasons {
    display: flex;
    flex-direction: column;
    .title-block {
        background: url('../../Magento_Cms/images/homepage/pattern.png') repeat-x bottom center get-color('midnight');
        color: $white;
        padding: 0.5rem 2rem;
    }
    .title {
        font-family: $sec-font;
        font-size: rem-calc(22);
    }
    .features-block {
        background: #efefef;
        padding: 0.5rem 2rem 1.5rem;
        .sub-title {
            margin-top: 2rem;
            font-family: $sec-font;
            font-size: rem-calc(18);
        }
    }
}

.testimonial-block {
    padding: 0 1.5rem;
}

.accs-wrapper {
    background-color: get-color('ice');
    border-radius: $global-radius;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    text-align: center;
    &.cable {
        padding-top: 0;
    }
    &.kit {
        margin-bottom: 0;
    }
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    .accs-img {
        margin-bottom: .5rem;
    }
}

.top-list-wrapper {
    background: #efefef;
    padding: 2rem 2rem 1rem;
    .heading {
        font-size: rem-calc(20);
        text-align: center;
    }
    .accordion-title {
        font-size: rem-calc(16);
        &:before {
            width: 10px;
            height: 10px;
            margin-top: rem-calc(-9);
        }
    }
    .top-list-item {
        a {
            color: $dark-gray;
            font-weight: normal;
        }
    }
}

@media screen and #{breakpoint(medium)} {
    .home-section {
        margin: 5rem auto;
    }
    .top-brands {
        display: flex;
        margin: 0 -0.75rem;
        flex-wrap: wrap;
        .wrapper {
            margin: 1rem 0.75rem;
            width: calc(50% - 2.25rem);
        }
    }
    .home-reasons {
        .title-block, .features-block {
            padding-left: 3rem;
            padding-right: 3rem;
        }
        .title {
            font-size: rem-calc(28);
        }
    }
    .button {
        &.mb-full {
            width: auto;
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }
    .testimonial-block {
        padding: 0;
    }
    .top-list-wrapper {
        padding: 2rem 4rem 1rem;
    }
    .accs-wrapper {
        padding: 0;
        .accs-box {
            float: right;
            &.content {
                padding: 2rem;
                width: 60%;
            }
            &.image {
                width: 40%;
            }
        }
        .accs-img {
            max-height: 160px;
            margin: 2rem 0;
            &.cable {
                float: right;
                margin: 0;
            }
        }
    }
}

@media screen and #{breakpoint(large)} {
    %heading-icon {
        content: url('../#{$svg-icons-path}cmy-swash.svg');
        width: 65px;
        display: inline-block;
        top: rem-calc(-2);
        position: relative;
    }
    .home-top-brands {
        .top-brands {
            flex-wrap: nowrap;
            &.top {
                .content {
                    width: 65%;
                    text-align: left;
                    margin-left: 1rem;
                }
                .wrapper {
                    display: flex;
                    padding: 1rem 1rem 1rem 0;
                    width: auto;
                }
                .img-wrapper {
                    width: 35%;
                }
            }
        }
        .wrapper {
            margin: 1rem 0.75rem;
            padding: 1rem 1rem 2rem;
        }
    }
    .home-help {
        text-align: center;
        .home-title {
            margin-left: 0;
        }
        .help-icon {
            margin-bottom: 1rem;
        }
        .title {
            font-size: rem-calc(20);
        }
    }
    .home-reasons {
        flex-direction: row;
        .title-block {
            width: 35%;
            display: flex;
            align-items: center;
            padding-left: 4rem;
            padding-right: 4rem;
            padding-right: 4rem;
        }
        .features-block {
            width: 75%;
            padding-left: 4rem;
            padding-right: 4rem;
            .sub-title {
                font-size: rem-calc(22);
            }
        }
    }
    .intro {
        text-align: center;
        .title {
            font-size: rem-calc(36);
            font-family: $sec-font;
            margin-bottom: 0.5rem;
        }
    }

    .top-list-block {
        margin-top: 7rem;
    }
    .top-list-wrapper {
        margin-top: 2rem;
        padding: 3rem 3rem 2rem;
        .heading {
            font-size: rem-calc(30);
            margin-bottom: 2rem;
        }
        .accordion-content {
            padding: 0;
            margin: 0 0 1.5rem;
        }
        .top-list {
            &-item {
                margin: .5rem 0;
            }
        }
    }

    .accs-wrapper {
        .accs-img {
            margin: 1rem 0;
        }
    }
}

$printing-profile-color: (
    "business-profile" : get-color('navy'),
    "home-profile": get-color('light-yellow'),
    "student-profile": get-color('teal'),
    "makers-profile": get-color('violet')
);

.printing-profile {
    margin-bottom: 2rem;
    figure {
        position: relative;
        cursor: pointer;
        max-width: rem-calc(350);
        margin: 0 auto 1.5rem;
        &:hover {
            figcaption {
                opacity: 1;
            }
        }
    }
    figcaption {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        box-sizing: border-box;
        height: 100%;
        width: 100%;
        padding: 0;
        span {
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            color: $white;
            font-weight: bold;
            z-index: 99;
        }
        &:after {
            opacity: 1;
            width: 100%;
            height: 100%;
            position: absolute;
            transition: all 0.4s;
            content: '';
            bottom: 0;
            right: 0;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        }
    }
    @each $profile, $color in $printing-profile-color {
        .#{$profile}:hover {
            figcaption {
                span {
                    background : $color;
                }
                &:after {
                    background : $color;
                }
            }
        }
    }
}

.home-section {
    .title, .heading {
        font-family: $sec-font;
        margin-bottom: 1rem;
    }
}

@media screen and #{breakpoint(medium)} {
    .printing-profile {
        margin-bottom: 0;
    }
}
@media screen and #{breakpoint(xlarge)} {
    .top-list-wrapper {
        dd {
            width: 25%;
        }
    }
}
