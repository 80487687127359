.hero-banner {
  text-align: center;
  margin-top: 2rem;
}
.hero-banner .banner-img {
  margin-top: 1rem;
  max-width: 75%;
}
.hero-banner .banner-content {
  padding: 2rem 1rem;
}

@media screen and (min-width: 48em) {
  .hero-banner .banner-img {
    margin-top: 0;
  }
  .hero-banner .banner-content {
    padding: 2rem 0 1.5rem;
  }
}
@media screen and (min-width: 64em) {
  .hero-banner .banner-content {
    padding: 4rem 0;
  }
}
@media screen and (min-width: 75em) {
  .hero-banner .banner-img {
    max-width: 70%;
  }
}
/*@import '../../Magento_Catalog/styles/printer-selector';*/
.cms-home .page-main {
  margin-top: 0;
}

.heading {
  margin-top: 0;
}

.home-section {
  margin: 4rem auto;
}

.home-top-brands .wrapper {
  background: #efefef;
  margin: 1rem 0;
  padding: 1rem 1rem 2rem;
}
.home-top-brands .content {
  text-align: center;
}
.home-top-brands .brand-icons {
  display: inline-block;
  margin: 1rem 0;
  filter: brightness(0.7);
}

.home-help {
  background-color: #3D3B3B;
  color: #ffffff;
  padding: 1rem 0;
}
.home-help .title {
  margin-top: 0;
  margin-bottom: 0.15rem;
}
.home-help .wrapper {
  margin: 0.5rem 0;
  padding: 0 2rem;
}
.home-help .content {
  padding-left: 1rem;
}
.home-help .help-icon {
  max-width: 100%;
}
.home-help .help-title {
  margin-left: 2rem;
  margin-bottom: 2rem;
  font-family: "factoria", "open-sans", Georgia, serif;
}

.home-reasons {
  display: flex;
  flex-direction: column;
}
.home-reasons .title-block {
  background: url("../../Magento_Cms/images/homepage/pattern.png") repeat-x bottom center #22303d;
  color: #ffffff;
  padding: 0.5rem 2rem;
}
.home-reasons .title {
  font-family: "factoria", "open-sans", Georgia, serif;
  font-size: 1.5714285714rem;
}
.home-reasons .features-block {
  background: #efefef;
  padding: 0.5rem 2rem 1.5rem;
}
.home-reasons .features-block .sub-title {
  margin-top: 2rem;
  font-family: "factoria", "open-sans", Georgia, serif;
  font-size: 1.2857142857rem;
}

.testimonial-block {
  padding: 0 1.5rem;
}

.accs-wrapper {
  background-color: #efefef;
  border-radius: 0;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  text-align: center;
}
.accs-wrapper.cable {
  padding-top: 0;
}
.accs-wrapper.kit {
  margin-bottom: 0;
}
.accs-wrapper:before, .accs-wrapper:after {
  content: "";
  display: table;
}
.accs-wrapper:after {
  clear: both;
}
.accs-wrapper .accs-img {
  margin-bottom: 0.5rem;
}

.top-list-wrapper {
  background: #efefef;
  padding: 2rem 2rem 1rem;
}
.top-list-wrapper .heading {
  font-size: 1.4285714286rem;
  text-align: center;
}
.top-list-wrapper .accordion-title {
  font-size: 1.1428571429rem;
}
.top-list-wrapper .accordion-title:before {
  width: 10px;
  height: 10px;
  margin-top: -0.6428571429rem;
}
.top-list-wrapper .top-list-item a {
  color: #3D3B3B;
  font-weight: normal;
}

@media screen and (min-width: 48em) {
  .home-section {
    margin: 5rem auto;
  }
  .top-brands {
    display: flex;
    margin: 0 -0.75rem;
    flex-wrap: wrap;
  }
  .top-brands .wrapper {
    margin: 1rem 0.75rem;
    width: calc(50% - 2.25rem);
  }
  .home-reasons .title-block, .home-reasons .features-block {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .home-reasons .title {
    font-size: 2rem;
  }
  .button.mb-full {
    width: auto;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .testimonial-block {
    padding: 0;
  }
  .top-list-wrapper {
    padding: 2rem 4rem 1rem;
  }
  .accs-wrapper {
    padding: 0;
  }
  .accs-wrapper .accs-box {
    float: right;
  }
  .accs-wrapper .accs-box.content {
    padding: 2rem;
    width: 60%;
  }
  .accs-wrapper .accs-box.image {
    width: 40%;
  }
  .accs-wrapper .accs-img {
    max-height: 160px;
    margin: 2rem 0;
  }
  .accs-wrapper .accs-img.cable {
    float: right;
    margin: 0;
  }
}
@media screen and (min-width: 64em) {
  .home-top-brands .top-brands {
    flex-wrap: nowrap;
  }
  .home-top-brands .top-brands.top .content {
    width: 65%;
    text-align: left;
    margin-left: 1rem;
  }
  .home-top-brands .top-brands.top .wrapper {
    display: flex;
    padding: 1rem 1rem 1rem 0;
    width: auto;
  }
  .home-top-brands .top-brands.top .img-wrapper {
    width: 35%;
  }
  .home-top-brands .wrapper {
    margin: 1rem 0.75rem;
    padding: 1rem 1rem 2rem;
  }
  .home-help {
    text-align: center;
  }
  .home-help .home-title {
    margin-left: 0;
  }
  .home-help .help-icon {
    margin-bottom: 1rem;
  }
  .home-help .title {
    font-size: 1.4285714286rem;
  }
  .home-reasons {
    flex-direction: row;
  }
  .home-reasons .title-block {
    width: 35%;
    display: flex;
    align-items: center;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-right: 4rem;
  }
  .home-reasons .features-block {
    width: 75%;
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .home-reasons .features-block .sub-title {
    font-size: 1.5714285714rem;
  }
  .intro {
    text-align: center;
  }
  .intro .title {
    font-size: 2.5714285714rem;
    font-family: "factoria", "open-sans", Georgia, serif;
    margin-bottom: 0.5rem;
  }
  .top-list-block {
    margin-top: 7rem;
  }
  .top-list-wrapper {
    margin-top: 2rem;
    padding: 3rem 3rem 2rem;
  }
  .top-list-wrapper .heading {
    font-size: 2.1428571429rem;
    margin-bottom: 2rem;
  }
  .top-list-wrapper .accordion-content {
    padding: 0;
    margin: 0 0 1.5rem;
  }
  .top-list-wrapper .top-list-item {
    margin: 0.5rem 0;
  }
  .accs-wrapper .accs-img {
    margin: 1rem 0;
  }
}
.printing-profile {
  margin-bottom: 2rem;
}
.printing-profile figure {
  position: relative;
  cursor: pointer;
  max-width: 25rem;
  margin: 0 auto 1.5rem;
}
.printing-profile figure:hover figcaption {
  opacity: 1;
}
.printing-profile figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0;
}
.printing-profile figcaption span {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  color: #ffffff;
  font-weight: bold;
  z-index: 99;
}
.printing-profile figcaption:after {
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.4s;
  content: "";
  bottom: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}
.printing-profile .business-profile:hover figcaption span {
  background: #34495E;
}
.printing-profile .business-profile:hover figcaption:after {
  background: #34495E;
}
.printing-profile .home-profile:hover figcaption span {
  background: #F39C12;
}
.printing-profile .home-profile:hover figcaption:after {
  background: #F39C12;
}
.printing-profile .student-profile:hover figcaption span {
  background: #5FAEB4;
}
.printing-profile .student-profile:hover figcaption:after {
  background: #5FAEB4;
}
.printing-profile .makers-profile:hover figcaption span {
  background: #A36D8F;
}
.printing-profile .makers-profile:hover figcaption:after {
  background: #A36D8F;
}

.home-section .title, .home-section .heading {
  font-family: "factoria", "open-sans", Georgia, serif;
  margin-bottom: 1rem;
}

@media screen and (min-width: 48em) {
  .printing-profile {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .top-list-wrapper dd {
    width: 25%;
  }
}