.hero-banner{
    text-align: center;
    margin-top: 2rem;
    .banner-img {
        margin-top: 1rem;
        max-width: 75%;
    }
  .banner-content{
    padding: 2rem 1rem;
  }
}

@media screen and #{breakpoint(medium)} {
  .hero-banner {
      .banner-img {
          margin-top: 0;
      }
    .banner-content {
      padding: 2rem 0 1.5rem;
    }
  }
}

@media screen and #{breakpoint(large)} {
  .hero-banner{
    .banner-content{
      padding: 4rem 0;
    }
  }
}

@media screen and #{breakpoint(xlarge)} {
    .hero-banner {
        .banner-img {
            max-width: 70%;
        }
    }
}
